<template>
    <div class="gateway-list">
        <app-list :opt="opt" @get="onGet" ref="myList"></app-list>
    </div>
</template>
<script>
let validMAC = (rule, value, callback) => {
    if (value == "" || value == undefined) {
        callback();
    } else {
        //支持输入大小写字母，数字，限制1~32位字符
        let reg = /^[A-z0-9]{1,32}$/;
        if (!reg.test(value)) {
            callback(new Error("MAC格式不对"));
        } else {
            callback();
        }
    }
};
export default {
    data() {
        let _this = this;
        return {
            dialogVisible: false,
            opt: {
                title: "网关列表",
                search: [{
                    key: "innerType",
                    label: "网关型号",
                    type: "select-no-filterable",
                    opt: {
                        list: [{
                            label: "WF-L2G",
                            value: "27"
                        },
                        {
                            label: "G4",
                            value: "200"
                        }
                        ]
                    }
                },
                {
                    key: "name",
                    label: "网关名称",
                    maxlength: 20,
                },
                {
                    key: "mac",
                    label: "网关MAC",
                },
                {
                    label: "网关状态",
                    key: "onLine",
                    type: "select-no-filterable",
                    opt: {
                        list: [{
                            label: "离线",
                            value: "0"
                        },
                        {
                            label: "在线",
                            value: "1"
                        }
                        ]
                    }
                },
                {
                    label: "房源名称",
                    key: "houseName",
                    type: "selectCity",
                    opt: {
                        list: []
                    }
                }
                ],
                columns: [{
                    label: "网关型号",
                    key: "innerTypeStr",
                },
                {
                    label: "网关名称",
                    key: "name",
                },
                {
                    label: "网关MAC",
                    key: "mac"
                },
                {
                    label: "网关WiFi名称",
                    key: "wifiName"
                },
                {
                    label: "关联门锁（把）",
                    key: "lockNum"
                },
                {
                    label: "房源/楼栋",
                    key: "houseInfo" 
                },
                {
                    label: "安装位置",
                    key: "location"
                },
                {
                    label: "网关状态",
                    key: "onLineStr"
                },
                {
                    label: "绑定时间",
                    key: "bindTime"
                },
                {
                    label: "操作",
                    key: "action",
                    type: "action-but",
                    opt: {
                        list: [{
                            label: "详情",
                            on(row) {
                                _this.$router.push({
                                    path: "/main/gateway/detail/show",
                                    query: {
                                        deviceToken: row.deviceToken,
                                        // houseId: row.houseId,
                                        deviceId: row.deviceId,
                                        rowData: row,
                                    }
                                })
                                _this.cache.setLS('gatewayRowData',row);
                            }
                        }]      
                    }
    
                },
                ],
                buttons: [{
                    type: 2,
                    on(data) {
                        if (data.ids.length > 1) {
                            return _this.$message({
                                showClose: true,
                                message: "不支持多个删除,只支持单个删除!",
                                type: "warning"
                            });
                        }
                        const h = _this.$createElement;
                        _this.$msgbox({
                            title: "删除提示",
                            message: h("p", null, [
                                h(
                                    "span", {
                                    style: "color: #333;font-size: 16px;margin-left:98px"
                                },
                                    "确定要删除吗？"
                                )
                            ]),
                            showCancelButton: true,
                            confirmButtonText: "确定",
                            cancelButtonText: "取消"
                        }).then(action => {
                            // 待调试        
                             if (data.list[0].innerType == 27) {
                                let dto = {
                                gatewayId: data.list[0].deviceId || data.list[0].deviceToken,
                            }
                                _this.get('/device-service/device/v1/bluetooth-gateway/delete-gateway/' + dto.gatewayId).then( res => {
                                _this.$message({
                                    showClose: true,
                                    message: "删除成功！",
                                    type: "success"
                                });
                                data.refresh();
                            });
                            } else {
                                let dto = {
                                gatewayId:  data.list[0].deviceToken,
                                houseId : data.list[0].houseId,
                                }
                                 _this.post("landlord-service/v1/house/tt/gateway/delete", dto, {
                                isUseResponse: true
                            }).then(function () {
                                _this.$message({
                                    showClose: true,
                                    message: "删除成功！",
                                    type: "success"
                                });
                                data.refresh();
                            });
                            }
                           
                        });
                    }
                }]
            },
        };
    },
    created() {
        console.log("gateway-list created!!");
    },
    activated() {
        this.getApartmentList();
    },

    methods: {
        onGet(opt) {
            let dto = {
                current: opt.skip,
                pageSize: opt.limit,
                ...opt.searchForm
            };
            this.post("landlord-service/v1/house/device/gateway-list", dto, {
                isUseResponse: true
            }).then(res => {
                let obj = {
                    data: {
                        data: [],
                        total: 0,
                    }
                }
                obj.data.total = res.data.data.total;
                obj.data.data = res.data.data.data ? res.data.data.data : [];
                if (obj.data.data && obj.data.data.length > 0) {
                    obj.data.data.forEach(item => {
                    if(item.buildingName){
                        item.houseInfo = item.houseName + '/' + item.buildingName;
                    }else{
                        item.houseInfo = item.houseName;
                    }
                        item.onLineStr = ["离线", "在线"][item.onLine];       
                        item.wifiName = item.wifiName ? item.wifiName : '--';
                        item.lockNum = item.lockNum ? item.lockNum : 0;
                        if (item.innerType == 27) {
                          item.innerTypeStr = 'WF-L2G';
                        } else if (item.innerType == 200) {
                          item.innerTypeStr = 'G4';
                        }
                    })
                }
                opt.cb(obj.data);
            });
        },
        //获取房源列表
        getApartmentList(data, province) {
            var dto = {
                pageNumber: "1",
                pageSize: "99999",
                landlordId: this.cache.getLS("userInfo")["roleType"] == "1" ?
                    null : this.cache.getLS("userInfo")["userId"],
                city: data,
                province: province
            };
            return new Promise((resolve, reject) => {
                this.post("landlord-service/apartment/queryApartmentInfo", dto).then(res => {
                    if (res) {
                        res = JSON.parse(JSON.stringify(res).replace(/name/g, "label"));
                        res = JSON.parse(JSON.stringify(res).replace(/id/g, "value"));
                        this.opt.search.forEach(item => {
                            if (item.key == "houseName") {
                                item.opt.list = res;
                            }
                        })
                        resolve(res);
                    }
                });
            });
        },
    }
};
</script>
<style lang="scss">

</style>